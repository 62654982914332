<template>
    <div class="patientBox">
        <h2 class="patient-title">환자 정보</h2>
        <!-- 등록된 환자 정보가 있는 경우 -->
        <div class="" v-if="targetInfo.patientSn">
            <ul class="patientList line">
                <li class="patient-input" v-for="(item, i) in vaildTargetHeader" :key="i">
                    <div class="input-title">{{item.name}}</div>
                    <div class="input-value">{{targetInfo[item.value]}}</div>
                </li>
                <!-- <li class="patient-input" v-if="currentRouteName === 'step1' && !targetInfo.isDicom">
                    <BtnCompo
                        :width="'100%'"
                        :height="'34px'"
                        :txtBtn="'환자 선택 변경'"
                        :fontSize="'14px'"
                        :fontWeight="'500'"
                        :borderRadius="'4px'"
                        :marginTop="'10px'"
                        :onClick="() => {
                            viewPatientList()
                    }" />
                </li> -->
            </ul>
        </div>
        <!-- 초기화 화면 && 이미지(jpg, png) 파일이 등록된 경우 -->
        <div v-else>
            <ul class="patientList line checkFirst">
                <li class="patient-input">
                    <div class="input-title">등록된 환자 정보가 없습니다</div>
                </li>
                <li class="patient-input">
                    <BtnCompo
                        :width="'100%'"
                        :height="'34px'"
                        :txtBtn="'환자 등록 및 목록 조회'"
                        :fontSize="'14px'"
                        :fontWeight="'500'"
                        :borderRadius="'4px'"
                        :marginTop="'10px'"
                        :onClick="() => {
                            viewPatientList()
                    }" />
                </li>
            </ul>
        </div>
        <v-dialog v-model="openPopup" width="320">
            <DefaultPopup
                :popupText="msg"
                :secondTxt="CONST_OK"
                :firstBtn="false"
                :onClick="() => { 
                    openPopup = false;
                    isNewPatient = true;
                    formValues.patientSn = patientSn
                 }"
                @closePopup="openPopup = false"
            />
        </v-dialog>
        <v-dialog v-model="patientPopupVisible" width="720">
            <PatientListPopup  @closePopup="closePopup" @selectedTargetInfo="setTargetInfo"/>
        </v-dialog>
    </div>
</template>

<script>
import EventBus from '../plugins/EventBus'
import { rules } from '../mixin/rules'

export default {
    components: {
        PatientListPopup: () => import('../popup/patientListPopup.vue'),
    },
    mixins: [rules],
    props: {
    },
    data() {
        return {
            formValues: {},
            patientSn: null,
            vaildTargetHeader: [{
                name: '환자번호',
                value: 'patientSn'
            },{
                name: '환자명',
                value: 'name'
            },{
                name: '생년월일',
                value: 'birth'
            },{
                name: '나이',
                value: 'age'
            },{
                name: '성별',
                value: 'sexStr'
            }],
            xrayMap: {},
            openPopup: false,
            msg: "",
            initialValue: '',
            targetInfo: JSON.parse(localStorage.getItem("targetInfo"))?? {},
            patientPopupVisible: false
        };
    },
    watch: {
    },
    created() {},
    mounted() {
    },
    destroyed() {},
    methods: {
        selectPatient(){
            if(!this.patientSn && !localStorage.getItem('targetInfo')) {
                this.showPopup('환자번호를 입력하세요.');
                return;
            }
            localStorage.removeItem('targetInfo')
            EventBus.emit('change_target');
        },
        viewPatientList(){
            this.patientPopupVisible = true
        },
        closePopup(){
            this.patientPopupVisible = false
        },
        setTargetInfo(value){
            this.targetInfo = value;
            if(localStorage.getItem('selectedTargetInfo')) {
                localStorage.setItem('targetInfo', localStorage.getItem('selectedTargetInfo'))
                localStorage.removeItem('selectedTargetInfo')
            }
            EventBus.emit('change_target');
        }
    },
    computed:{
        currentRouteName() {
            return this.$route.name;
        },
    },
};
</script>

<style lang="scss" scoped>
@import "@/sass/app.scss";
.input-title{width: calc(100% - 180px)}
.checkFirst{
    .input-title{width: 100%; text-align: center;}
    .button-wrap{position: static; padding: 15px 0;}
    &.input-wrap{
        display: flex; justify-content: space-between; align-items: center; gap: 5px;
        .input-value{
            width: calc(180px - 30%);
            .input{width: 100%; padding: 0 5px; }
        }
    }
}

.registForm{
    .input-value{width: 180px !important;}
}

.input{width: 100%; padding: 0 10px; }

.inpSet{display:inline-flex; align-items:center; gap:4px; color: #fff !important; width: 180px;}
.inpSet .srh{position:relative; display:inline-flex; flex-direction:row; flex-wrap:nowrap; gap:0; justify-content:space-between; background:#fff; border:1px solid #ccc; border-radius:var(--border); overflow:hidden; min-width:200px; height:var(--inputH);}
.inpSet .srh input{ padding:4px 0 4px 16px ; font-size: var(--fz-body); color:#fff; height:100%; width:calc(100% - 50px); border:none; outline:none;}
.inpSet .srhBtn{display:inline-flex; align-items:center; justify-content:center; width:var(--inputH); height:var(--inputH); color:#fff; background:var(--color-p20);}
.inpSet .inp{position:relative; display:inline-flex; align-items:center; background:#fff; border:1px solid #ccc; border-radius:var(--border); overflow:hidden; min-width:200px; height:var(--inputH);}
.inpSet .inp input{padding:4px 16px; font-size: var(--fz-body); color:#fff; height:100%; width:100%; border:none; outline:none;}

::v-deep .v-input--selection-controls{margin: 0;}
::v-deep .v-label{color: #fff;}

</style>